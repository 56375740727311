<template>
  <div>
    <div class="mid_boxs">
      <div class="mid_box_left_">
        <div class="mid_box_left_bt_top">
          <span>公司部门列表</span>
        </div>
        <div class="mid_box_left_top">
          <span>公司：</span>
          <el-select class="sbtn" v-model="Cpy" @change="getDept" placeholder="请选择" filterable>
            <el-option
              v-for="item in Companydata1"
              :key="item.value"
              :label="item.companyName"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="mid_box_left_bt" :style="{ height: elementHeight - 70 + 'px' }">
          <el-tree :data="deptdata" :props="defaultProps1" accordion  @node-click="handleNodeClick1"></el-tree>
        </div>
      </div>
      <div class="mid_box_right_">
        <div class="mid_box_left_bt_top" style="margin: 0 20px">
          <span>岗位设置</span>
          <i class="post-icon-plus el-icon-plus" @click="toAdd"></i>
        </div>
        <el-table :data="tableData" style="width: 100%" :height="elementHeight">
          <el-table-column prop="postName" label="岗位名称"></el-table-column>
          <el-table-column prop="postNo" label="岗位编号" ></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <i id="i" class="el-icon-delete" @click="deleteItem(scope.row)"></i>
              <i id="i" class="el-icon-edit" @click="editItem(scope.row)"></i>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-dialog title="新增岗位" :visible.sync="dialogVisible" width="20%">
      <el-form
        label-width="80px"
        :model="formLabelAlign1"
        :rules="rules"
        ref="ruleForm"
        :show-message="false"
      >
        <el-form-item label="岗位名称" prop="postName">
          <el-input v-model="formLabelAlign1.postName"></el-input>
        </el-form-item>
        <el-form-item label="岗位编号">
          <el-input v-model="formLabelAlign1.postNo"></el-input>
        </el-form-item>
        <el-form-item label="上级公司">
          <el-select
            class="elselect"
            v-model="formLabelAlign1.upCompanyId"
            placeholder="请选择"
            clearable
            @change="FindDept"
          >
            <el-option
              v-for="item in Companydata1"
              :key="item.value"
              :label="item.companyName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上级部门" prop="upDeptmentId">
          <el-select
            class="elselect"
            v-model="formLabelAlign1.upDeptmentId"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in deptdata1"
              :key="item.value"
              :label="item.deptmentName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addItem">保存并新增</el-button>
        <el-button type="primary" @click="addItem1">确 定</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="修改岗位" :visible.sync="dialogVisible1" width="20%">
      <el-form
        label-width="80px"
        :model="formLabelAlign1"
        :rules="rules"
        ref="ruleForm"
        :show-message="false"
      >
        <el-form-item label="岗位名称" prop="postName">
          <el-input v-model="formLabelAlign1.postName"></el-input>
        </el-form-item>
        <el-form-item label="岗位编号">
          <el-input v-model="formLabelAlign1.postNo"></el-input>
        </el-form-item>
        <el-form-item label="上级公司">
          <el-select
            class="elselect"
            v-model="formLabelAlign1.upCompanyId"
            placeholder="请选择"
            @change="FindDept"
          >
            <el-option
              v-for="item in Companydata1"
              :key="item.value"
              :label="item.companyName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上级部门" prop="upDeptmentId">
          <el-select class="elselect" v-model="formLabelAlign1.upDeptmentId" placeholder="请选择">
            <el-option
              v-for="item in deptdata1"
              :key="item.value"
              :label="item.deptmentName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="exidItem1">确 定</el-button>
        <el-button @click="dialogVisible1 = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { deleteMethod, get, post, put } from "../../api/http";

export default {
  data() {
    return {
      elementHeight: 0,
      Companydata1: [],
      Cpy: "",
      deptdata: [],
      deptdata1: [],
      tableData: [],
      formLabelAlign1: {
        id: 0,
        postNo: "",
        postName: "",
        upCompanyId: "",
        upDeptmentId: "",
        tenantId: 0,
        isDelete: 0,
        saveTime: new Date().toISOString(),
        saveUserId: "",
      },
      dialogVisible: false,
      dialogVisible1: false,
      defaultProps1: {
        children: "deptlist",
        label: "deptmentName",
      },
      rules: {
        postName: [
          { required: true, message: "请输入岗位名称", trigger: "blur" },
        ],
        upDeptmentId:[
          { required: true, message: '请选择上级部门', trigger: 'change' }
        ]
      },
      deptId:null,
    };
  },
  created() {
    this.Cpy = JSON.parse(sessionStorage.getItem("userInfo")).upCompanyId;
    let dep = JSON.parse(sessionStorage.getItem("userInfo")).upDeptmentId;
    this.getDept(this.Cpy);
    this.deptId = dep;
    get("/api/Post?CompId=" + this.Cpy + "&DeptId=" + dep).then((res) => {
      this.tableData = res.data;
    });
  },
  mounted() {
    this.formLabelAlign1.tenantId = this.$store.state.tenantId;
    this.formLabelAlign1.saveUserId = this.$store.state.userInfo.id + "";
    this.Companydata1 = this.$store.state.companyList;
    this.getElementHeight();
  },
  methods: {
    getElementHeight() {
      var clientHeight = window.innerHeight;
      this.$nextTick(() => {
        this.elementHeight = clientHeight - (60 + 10 + 70);
      });
    },
    getDept(e) {
      this.tableData = [];
      get("/api/Department/GetTree?CompId=" + e).then((res) => {
        this.deptdata = res.data;
        this.deptId = res.data.length > 0?res.data[0].id:'';
      });
    },
    toAdd() {
      this.formLabelAlign1.id = 0;
      this.formLabelAlign1.postNo = "";
      this.formLabelAlign1.postName = "";
      this.formLabelAlign1.upCompanyId = this.Cpy;
      this.formLabelAlign1.upDeptmentId = this.deptId;
      this.dialogVisible = true;
      get("/api/Department/GetNoTree?CompId=" + this.Cpy).then((res) => {
        this.deptdata1 = res.data;
      });
    },
    handleNodeClick1(e) {
      this.deptId = e.id;
      get("/api/Post?CompId=" + this.Cpy + "&DeptId=" + e.id).then((res) => {
        this.tableData = res.data;
      });
    },
    FindDept(e) {
      this.Cpy = e
      get("/api/Department/GetNoTree?CompId=" + e).then((res) => {
        this.deptdata1 = res.data;
        this.formLabelAlign1.upDeptmentId = null;
      });
      this.getDept(e)
    },
    addItem() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          // this.formLabelAlign1.upDeptmentId = Number(
          //   this.formLabelAlign1.upDeptmentId
          // );
          post("/api/Post", this.formLabelAlign1)
            .then((res) => {
              this.$message({
                type: "success",
                message: res.message,
              });
              //this.toAdd();
            })
            .then(() => {
              get(
                "/api/Post?CompId=" +
                  this.formLabelAlign1.upCompanyId +
                  "&DeptId=" +
                  this.formLabelAlign1.upDeptmentId
              ).then((res) => {
                this.tableData = res.data;
                this.formLabelAlign1.postNo = "";
                this.formLabelAlign1.postName = "";
              });
            });
          // this.toAdd();
          
        }
      });
    },
    addItem1() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.formLabelAlign1.upDeptmentId = Number(
            this.formLabelAlign1.upDeptmentId
          );
          post("/api/Post", this.formLabelAlign1)
            .then((res) => {
              this.$message({
                type: "success",
                message: res.message,
              });
              //this.toAdd();
            })
            .then(() => {
              get(
                "/api/Post?CompId=" +
                  this.formLabelAlign1.upCompanyId +
                  "&DeptId=" +
                  this.formLabelAlign1.upDeptmentId
              ).then((res) => {
                this.tableData = res.data;
              });
            });
          this.dialogVisible = false;
        }
      });
    },
    deleteItem(e) {
      this.$confirm("此操作将永久删岗位, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log(e);
          deleteMethod("/api/Post?Id=" + e.id)
            .then((res) => {
              this.$message({
                type: "success",
                message: res.message,
              });
            })
            .then(() => {
              get(
                "/api/Post?CompId=" +
                  e.upCompanyId +
                  "&DeptId=" +
                  e.upDeptmentId
              ).then((res) => {
                console.log(res);
                this.tableData = res.data;
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    editItem(e) {
      this.formLabelAlign1 = e;
      get("/api/Department/GetNoTree?CompId=" + e.upCompanyId).then((res) => {
        this.deptdata1 = res.data;
      });
      this.dialogVisible1 = true;
    },
    exidItem1() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.formLabelAlign1.upDeptmentId = Number(
            this.formLabelAlign1.upDeptmentId
          );
          put("/api/Post", this.formLabelAlign1).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
          });
          this.dialogVisible1 = false;
        }
      });
    },
  },
};
</script>

<style scoped>
@import "../../assets/style/Post.css";
</style>
